$cdn: 'https://files.maner.cloud/coach';
$btnDefault: #546FFF;

.member-tabs .ant-tabs-nav::before {
  opacity: 0;
}

.member-input-password .ant-input {
  background: #f5f5f7;
}

.member-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background: #f5f5f7 !important;
}

.member-icon {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.ant-table-thead>tr>th {
  color: #54577a !important;
  background: #fff !important;
  font-weight: normal !important;
}

.ant-table-thead>tr>th::before {
  background: transparent !important;
}

.ant-table-tbody>tr>td {
  color: #141522;
}

.no-padding-body .ant-modal-content {
  padding: 0 !important;
}

.form-error-12 .ant-form-item-explain-error {
  font-size: 12px;
}




@mixin ellipsis($line-count) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  /* 显示的行数 */
  -webkit-box-orient: vertical;
  white-space: normal;
}

@mixin btnHover($color: #546FFF, $bgColor: #F5F5F7, $show-bgColor: false) {
  color: $color;

  @if $show-bgColor {
    background-color: $bgColor;
  }
}

@mixin btnHoverIcon($color: $btnDefault, $fill: $btnDefault) {
  color: $color;
  fill: $fill
}

@mixin btnHoverTextColor($color: #546FFF) {
  color: $color !important;
}

@mixin btnHoverIconColor($color: #546FFF) {
  --icon-color: #546FFF;
}

@mixin btnHoverTextBg($color: #546FFF) {
  color: rgba(250, 250, 250, 0.8);
  background-color: rgba(84, 111, 255, 0.6);
}



.cursor::after {
  content: "▎";
  animation: blink 1s infinite;
  margin-left: 0.25rem;
  vertical-align: baseline;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
