@import "./styles/basic.scss";
.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  // min-height: 800px;

  .left {
    box-sizing: border-box;
    width: 53vw;
    padding: 325px 95px 0 130px;
    height: 100vh;
    // min-height: 800px;
    background-image: url(#{$cdn} + "/images/login_bgi.png");
    background-size: 100% 100%;
    background-position: center;
    .title {
      color: #fff;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px; /* 57.5% */
      margin-bottom: 9px;
      display: flex;
      align-items: center;

      > img {
        margin-right: 17.7px;
        width: 77.23px;
        height: 77.23px;
      }
    }
    .des {
      color: #fff;
      font-family: Source Han Sans CN;
      font-size: 20px;
      font-style: normal;
      // font-weight: 700;
      line-height: 48px; /* 240% */
      // > img {
      //   width: 33.9px;
      //   height: 33.9px;
      //   margin-right: 7.8px;
      //   position: relative;
      //   top: 8px;
      // }
    }
  }
  .right {
    width: 47vw;
    height: 100vh;
    text-align: center;
    padding-top: 210px;
    padding-left: 134px;
    padding-right: 134px;
    // min-height: 800px;
    .formArea {
      min-width: 400px;
      .formTitle {
        color: var(--secondary-500, #141522);
        font-family: Source Han Sans CN;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
        letter-spacing: -0.48px;
        margin-bottom: 43px;
      }

      .verify_code {
        box-shadow: none;

        :global {
          .ant-input-suffix {
            height: 24px;
          }
        }
      }
    }
  }
}
